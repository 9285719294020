import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { navLinks } from "../../config"

const StyledNav = styled.nav`
  display: none;


  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 31.25rem;
    background: ${({ theme }) => theme.colors.background};
    a {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  .nav-link {
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    padding: 0;
    &::before {
      transition: 200ms ease-out;
      height: 0.1563rem;
      content: "";
      position: absolute;
      background-color: ${({ theme }) => theme.colors.primary};
      width: 0%;
      bottom: -0.125rem;
    }
    &:hover::before {
      width: 100%;
    }
  }
  .cta-btn {
    width: auto;
    height: auto;
    font-weight: 700;
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 0.125rem solid ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.background};
    transition: 20ms ease-out;
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
    margin: 0;
    &:hover {
      background: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.background};
    }
  }
`

const Navbar = ({ lang }) => {
  const { menu, button } = navLinks
  return (
    <StyledNav style={{ backgroundColor: 'transparent' }}>
      {menu.map(({ name, url }, key, arr) => {
        const _url = lang === 'tw' ? url : url.replace('/', '/en/')
        return (
          <Link className="nav-link" key={key} to={_url}>
            {name}
          </Link>
        )
      })}

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', fontSize: '1rem' }}>

        <a href='/' style={lang === 'tw' ? { backgroundColor: '#FFF4E0', borderRadius: 5, width: 40, height: 40, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', } : {}}>
          正
        </a>
        &nbsp;&nbsp;&nbsp;
        <a href='/en/' style={lang === 'en' ? { backgroundColor: '#FFF4E0', borderRadius: 5, width: 40, height: 40, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', } : {}}>

          EN
        </a>
      </div>

      {button && (button.useFileName ? (
        <a
          className="cta-btn"
          href={`/${button.fileName}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {button.name}
        </a>
      ) : (
        <Link className="cta-btn" to={button.url}>
          {button.name}
        </Link>
      ))}
    </StyledNav>
  )
}

export default Navbar
